.heddiv{
    z-index: 100;
    background-color: rgb(15, 11, 42);
    color: white;
    box-shadow: 0px 1px 4px black;
    margin-bottom: 0.2em;
}
*{
    box-sizing: border-box;
}
.heddiv h2{
    margin: 0 1em 0 1.7em;
    padding: 0.8em;
    font-size: 30px;
}

