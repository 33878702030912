*{
    box-sizing: border-box;
}

.main{
    background-color: #f2f2f2;
    position: absolute;;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;    
}

.cntr{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
}

.logform{
    border-radius: 20px;
    padding: 2.5em;
    background-color: white;
    border-bottom: 1px solid #f2f2f2;
    width: 100%;
    max-width: 450px;
    box-sizing: border-box;
}

.hedundr{
    border-bottom:2px solid rgb(100, 81, 223);
    display:inline;
    font-size:23px;
    padding-bottom:4px;
}

.loginpt{
    outline: none;
    border: 1.5px solid rgb(238, 217, 217);
    padding: 1em;
    margin: 0.3em 0px 2em 0px;
    border-radius: 6px;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.3s;
}
.loginpt:focus{
    box-shadow: 0px 0px 7px rgb(100, 81, 223);
    border: 1.5px solid rgba(100, 81, 223, 0.509);
}

.logsbmt{
    background-color:rgb(100, 81, 223);
    color:white;
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 1em;
    cursor: pointer;
    transition: all 0.3s;
}
.logsbmt:hover{
    background-color: rgba(100, 81, 223, 0.835);
}
.logfrgt{
    background-color: transparent;
    float: right;
    border: none;
    padding: 1em;
    cursor: pointer;
}

.para{
    color: #817f7f;
    font-size: 15px;
    font-weight:300;
    margin-bottom: 0px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's on top of other elements */
  }

  .spinner {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: #3498db;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  