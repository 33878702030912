*{
    box-sizing: border-box !important;
}
.card{
    padding: 1em !important;
    margin: 1em !important;
    transition: all 0.3s;
    cursor: pointer;
}

.card h4{
    font-size: 1em;
    color: rgba(0, 0, 255, 0.863)
}

.card p{
    font-size: 0.80em;
}

.card:hover{
    transform: translateY(-2px);
    box-shadow: 0px 2px 4px rgb(112, 110, 110);
}